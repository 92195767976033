// import React, { useState } from "react";
import Row from 'react-bootstrap/Row';
import arrow from "../images/arrow.svg";
import * as Constants from "../utils/constants";
import breakpointsHelper from "../utils/breakpointsHelper";
// import { Redirect } from 'react-router';
//  @ts-ignore
import video from "../videos/Coche - 11490.mp4";
import { useWindowSize } from "@uidotdev/usehooks";

const VideoLoop = () => {
  const size = useWindowSize();
  // const [redirect, setRedirect] = useState(false); // eslint-disable-line

  // const handleOnClick = () => {
  //   setRedirect(true);
  // }

  // @ts-ignore
  const logoHeight = breakpointsHelper(size.width, [
    "100px",
    "150px",
    "150px",
    "150px",
    "150px"
  ])

  // @ts-ignore
  const videoFontSize = breakpointsHelper(size.width, [
    "20px",
    "25px",
    "25px",
    "28px",
    "30px"
  ])

  // if (redirect) {
  //   return <Redirect push to="/contactanos" />;
  // }

  return (
    <Row>
      <div className="content">
        <video width="100vw" autoPlay muted controls={false} playsInline={true} loop>
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div id="over">
          <div style={{ marginTop: "200px", fontSize: "39px" }}>
            <div>
              <img
                height={logoHeight}
                src={Constants.largeLogo}
                alt={Constants.largeLogo}
              ></img>
            </div>
            <br />
            <div style={{ fontFamily: "utopiaSemibold", fontSize: videoFontSize }}>
              Una manera inteligente de<br />invertir tus prioridades
            </div>
            <div style={{ fontFamily: "HelveticaLTStd-Roman", fontSize: "25px", marginTop: "2.5rem" }}>CONOCE MÁS</div>
            <img className="bounce" src={arrow} style={{ height: "2.2rem", marginTop: "2rem" }} alt={arrow} />
          </div>
        </div>
      </div>
    </Row>
  );
};

export default VideoLoop;