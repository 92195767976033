// Investment test API:
export const getAllPromptsURL: string = "https://winvest.ar:9098/webpage/findAllQuestions";
export const getInvestmentLevelsURL: string = "https://winvest.ar:9098/webpage/findAllProfile";

// Reports API:
export const getReportsListURL: string = "https://winvest.ar:9098/webpage/findAllReports";

// Accounts data
export const websiteUrl = "https://winvest.ar";
export const contactApi = `https://winvest.ar:9098/api/sendMail`;
export const recaptchaPublicKey = "6LfCuG8eAAAAACHoqyJwim93oye3rOAgRYCsvog0";
export const emailLogoURL: string = `${websiteUrl}/WINVEST_LOGOEMAIL.png`;
export const dataFiscalURL: string = "http://qr.afip.gob.ar/?qr=nmaETTb6O6cvVirVjYKbJg,,";
// Naming convention for products in stock in Google Sheet:
export const flagForProductsInStock: string = "SI";

// PDF links:
export const pdfAranceles: string = "http://winvest.ar/files/aranceles.pdf";
export const pdfPersonalIdoneo: string = "http://winvest.ar/files/PersonalIdoneoLebsa.pdf";
export const pdfCodigoDeConducta: string = "http://winvest.ar/files/C%C3%B3digoConducta.pdf";

// Template data
export const sections = [
  {
    visibleName: "HOME",
    componentName: "Start",
    path: "",
    showInNavbar: false
  },
  {
    visibleName: "SOBRE WINVEST",
    componentName: "About",
    path: "sobre_winvest",
    showInNavbar: true
  },
  {
    visibleName: "ALIANZAS",
    componentName: "Alliances",
    path: "alianzas",
    showInNavbar: true
  },
  {
    visibleName: "SERVICIOS",
    componentName: "Services",
    path: "servicios",
    showInNavbar: true,
    children: [
      {
        visibleName: "Asesoramiento",
        componentName: "Asesoramiento",
        path: "asesoramiento",
        showInNavbar: true
      },
      {
        visibleName: "Productos",
        componentName: "Products",
        path: "productos",
        showInNavbar: true
      },
      {
        visibleName: "Portafolios Sugeridos",
        componentName: "SuggestedPortfolios",
        path: "portafolios_sugeridos",
        showInNavbar: true
      },
      {
        visibleName: "Empresas",
        componentName: "Empresas",
        path: "empresas",
        showInNavbar: true
      }
    ]
  },
  {
    visibleName: "APERTURA DE CUENTAS",
    componentName: "Accounts",
    path: "apertura_de_cuentas",
    showInNavbar: true
  },
  {
    visibleName: "INFORMES",
    componentName: "Reports",
    path: "informes",
    showInNavbar: true
  },
  {
    visibleName: "PERFIL INVERSOR",
    componentName: "InvestorProfile",
    path: "perfil_del_inversor",
    showInNavbar: true
  },
  // {
  //   visibleName: "COTIZACIONES",
  //   componentName: "Cotizaciones",
  //   path: "cotizaciones",
  //   showInNavbar: true
  // },
  {
    visibleName: "TÉRMINOS Y CONDICIONES",
    componentName: "TerminosYCondiciones",
    path: "terminos_y_condiciones",
    showInNavbar: false
  },
  {
    visibleName: "POLÍTICA DE PRIVACIDAD",
    componentName: "PoliticaDePrivacidad",
    path: "politica_de_privacidad",
    showInNavbar: false
  },
  {
    visibleName: "LINKS ÚTILES",
    componentName: "Links",
    path: "links_utiles",
    showInNavbar: false
  },
  {
    visibleName: "CONTACTO",
    componentName: "Contact",
    path: "contactanos",
    showInNavbar: true
  }
];

export const mainCarouselImagesMobile = [
  require("../images/slide_nosotros_mobile.png").default,
  require("../images/slide_alianzas_mobile.png").default,
  require("../images/slide_hacemos_mobile.png").default,
  require("../images/slide_crece_mobile.png").default
]

export const mainCarouselImagesLinks = [
  "/sobre_winvest",
  "/alianzas",
  "/servicios/asesoramiento",
  "/apertura_de_cuentas"
]

export const mainCarouselBackgroundImages = [
  require("../images/nosotros_background.png").default,
  require("../images/alianzas_background.png").default,
  require("../images/hacemos_background.png").default,
  require("../images/crece_background.png").default,
]

export const empresaRowsData: any [] = [
  {
    title: "Inversiones",
    subtitle: "Diseñamos estrategias de corto, mediano y largo plazo. A partir de los excedentes de liquidez de las compañías, desarrollamos carteras con instrumentos locales e internacionales.",
    bullets: [
      "Gestionamos todas las operaciones de renta fija y renta variable.",
      "Realizamos arbitraje de bonos.",
      "Armamos estrategias con derivados financieros (cobertura y futuros).",
      "Invertimos en Fondos Comunes de Inversión de gestoras locales e internacionales con prestigio en el Mercado de Capitales."
    ],
    bgColor: "#F2F3F8"
  },
  {
    title: "Financiamiento",
    subtitle: "Trabajamos sobre todas las alternativas de financiamiento, asesorando a nuestros clientes en la gestión de su tesorería.",
    bullets: [
      "Negociación de Cheques de Pago Diferido, Echeqs y Facturas de Crédito Electrónico.",
      "Fideicomisos financieros.",
      "Cauciones bursátiles.",
      "Pagaré bursátil."
    ],
    epigraph: "Además, trabajamos en alianza con diversas Sociedades de Garantía Recíproca (SGR), permitiendo el acceso a las mejores condiciones de financiamiento del mercado.",
    bgColor: "#E6E6E6"
  },
  {
    title: "Cobertura",
    subtitle: "Desarrollamos diferentes estrategias de cobertura para la fluctuación de tipo de cambio.",
    bullets: [
      "Compra - venta de futuros, commodities y derivados.",
      "Dólar CCL y dólar MEP.",
      "Instrumentos dólar linked.",
      "Instrumentos hard dollar.",
      "Negociación de CEDEARs."
      
    ],
    bgColor: "#F2F3F8"
  }
];

export const hombreNegociosTablet = require("../images/hombre-negocios-trabajando-tableta-cafe@2x.png").default;
export const losTiemposCambiaron = require("../images/LosTiemposCambiaron.png").default;

export const mobileApp = require("../images/mobileApp.png").default;
export const ahoraInvertirEsFacil = require("../images/AhoraInvertirEsFacil.png").default;

export const homeTextImage = require("../images/Home_Texto.png").default;
export const plusSignImage = require("../images/signomas.svg").default;

export const imgConservador = require("../images/conservador.png").default;
export const imgModerado = require("../images/moderado.png").default;
export const imgArriesgado = require("../images/agresivo.png").default;

export const investmentProfiles = [
  {
    name: "Conservador",
    image: require("../images/conservador.png").default,
    description: [
      "• Busca diversificar sus inversiones con un horizonte de corto plazo.",
      "• Tiene por objetivo lograr rendimientos relativamente estables.",
      "• Espera una menor volatilidad en la valuación de su portafolio de inversiones.",
      "• Por lo general, necesita disponer de mayor liquidez."
    ]
  },
  {
    name: "Moderado",
    image: require("../images/moderado.png").default,
    description: [
      "• Asume ciertas oscilaciones en el rendimiento esperado de su cartera.",
      "• Invierte a mediano y hasta largo plazo parte de su portafolio, si pudiera aprovechar oportunidades de mercado que provean mayores expectativas de rendimientos."
    ]
  },
  {
    name: "Agresivo",
    image: require("../images/agresivo.png").default,
    description: [
      "• Tiene como objetivo principal lograr una alta rentabilidad para el promedio de su cartera, asumiendo que tendrá momentos de alzas y bajas en función del comportamiento del mercado.",
      "• Está dispuesto a mantener su inversión por largos períodos.",
      "• Está familiarizado con inversiones en acciones, divisas, derivados o productos similares.",
      "• Se mantiene actualizado con información financiera y de mercado."
    ]
  }
]

export const iconCalendar = require("../images/calendar.svg").default;
export const iconPlus = require("../images/plus.svg").default;
export const iconChart = require("../images/chart.svg").default;
export const iconPointChart = require("../images/pointChart.svg").default;

export const financiamiento = require("../images/financiamiento.jpg").default;
export const inversiones = require("../images/inversiones.jpg").default;

export const homeTexture = require("../images/texturahome.svg").default;

export const bannerArrow = require("../images/FLECHITA.svg").default;

export const aboutImage = require("../images/SobreWinvest.png").default;

export const ourTeamTexture = require("../images/Textura_nuestroequipo.svg").default;

export const nuestrosValoresImg1 = require("../images/Nuestros_valores_1.png").default;
export const nuestrosValoresImg2 = require("../images/Nuestros_valores_2.png").default;
export const nuestrosValoresImg3 = require("../images/Nuestros_valores_3.png").default;

export const alianzasImg = require("../images/Alianzas.png").default;

export const blueWinvestLogo = require("../images/Logo_azul_winvest.svg").default;

export const envelopeIcon = require("../images/sobre.svg").default;
export const phoneIcon = require("../images/telefono.svg").default;
export const pinIcon = require("../images/globo.svg").default;

export const envelopeIconFooter = require("../images/sobre_footer.svg").default;
export const phoneIconFooter = require("../images/telefono_footer.svg").default;
export const pinIconFooter = require("../images/globo_footer.svg").default;

export const admLocales = require("../images/Admlocales.png").default;
export const adm1 = {
  img: require("../images/Delta.png").default,
  url: "https://deltaam.com.ar/"
}
export const adm2 = { img: require("../images/AdCap.png").default, url: "https://ad-cap.com.ar/" };
export const adm3 = { img: require("../images/Schroders.png").default, url: "https://www.schroders.com/" };
export const adm4 = { img: require("../images/Compass group.png").default, url: "http://cgcompass.com/" };
export const adm5 = { img: require("../images/Galileo.png").default, url: "https://www.galileoargentina.com.ar/" };
export const adm6 = { img: require("../images/SBS.png").default, url: "https://www.gruposbs.com/" };
export const adm7 = { img: require("../images/megaqm.png").default, url: "https://megaqm.com.ar/" };
export const adm8 = { img: require("../images/allaria.png").default, url: "https://www.allaria.com.ar/" };

export const admGlobales = require("../images/Admglobales.png").default;

export const admG1 = { img: require("../images/NinetyOne.png").default, url: "https://ninetyone.com/" };
export const admG2 = { img: require("../images/JanusHenderson.png").default, url: "https://www.janushenderson.com/" };
export const admG3 = { img: require("../images/MFS.png").default, url: "https://www.mfs.com/" };
export const admG4 = { img: require("../images/NeubergerBerman.png").default, url: "https://www.nb.com/" };
export const admG5 = { img: require("../images/PIMCO.png").default, url: "https://latam.pimco.com/" };
export const admG6 = { img: require("../images/NewCapital.png").default, url: "https://www.efgam.com/" };
export const admG7 = { img: require("../images/FranklinTempleton.png").default, url: "https://www.franklintempleton.com.es/" };
export const admG8 = { img: require("../images/Robeco.png").default, url: "https://www.robeco.com/" };
export const admG9 = { img: require("../images/AB.png").default, url: "https://www.alliancebernstein.com/" };
export const admG10 = { img: require("../images/JUPITER.png").default, url: "https://www.jupiteram.com/" };
export const admG11 = { img: require("../images/GoldenHind.png").default, url: "https://goldenhindinvestments.com" };
export const admG12 = { img: require("../images/Barings.png").default, url: "https://www.barings.com/en-us/guest" };

export const handshake = require("../images/handshake.png").default;
export const pilares = require("../images/pilares.png").default;
export const valores = require("../images/valores.png").default;
export const typing = require("../images/typing.png").default;
export const showing = require("../images/showing.png").default;
export const vision = require("../images/vision.png").default;

export const servicesData = [
  {
    cta: "ASESORAMIENTO",
    path: "/servicios/asesoramiento",
    image: require("../images/asesoramiento.png").default
  },
  {
    cta: "PRODUCTOS",
    path: "/servicios/productos",
    image: require("../images/productos.png").default
  },
  {
    cta: "PORTAFOLIOS SUGERIDOS",
    path: "/servicios/portafolios_sugeridos",
    image: require("../images/portafolios.png").default
  },
  {
    cta: "EMPRESAS",
    path: "/servicios/empresas",
    image: require("../images/empresas.png").default
  }
]

export const servicesBg = require("../images/hombre-revisando-documento.jpg").default;
export const asesoramientoBg = require("../images/asesoramiento-hombre-bg.png").default;
export const empresasBg = require("../images/bgEmpresas.png").default;
export const portfolioSugerido = require("../images/portfolio_sugerido.png").default;

export const servicesImages = [
  require("../images/noun-distribution-2764037.svg").default,
  require("../images/noun-coins-919008.svg").default,
  require("../images/noun-transaction-2183618.svg").default,
  require("../images/noun-bond-3967567.svg").default,
  require("../images/noun-future-3857049.svg").default,
  require("../images/noun-escrow-account-3631234.svg").default,
  require("../images/noun-funding-2763957.svg").default,
  require("../images/noun-administration-2872961.svg").default,
  require("../images/noun-electronic-bill-4743811.svg").default
];

export const servicesTextParagraphs: string[] = [
  "Distribución, colocación y negociación de instrumentos de Renta Variable y Renta Fija.",
  "Productos y servicios relacionados con operaciones de cambio de Monedas y Derivados.",
  "Compra-venta de acciones de empresas que cotizan en la Bolsa de Comercio de Buenos Aires (BCBA).",
  "Compra-venta de ADRs y CEDEARs. Compra-venta de bonos públicos y privados.",
  "Compra-venta de opciones. Desarrollo de estrategias de cobertura. Compra- venta de futuros sobre monedas.",
  "Inversiones en Fideicomisos Financieros.",
  "Colocación u obtención de fondos a través de cauciones bursátiles, tanto en dólares como en pesos, con garantía de títulos de valores.",
  "Inversiones en Fondos Comunes de Inversión, a través de nuestros acuerdos independientes con las más prestigiosas administradoras del mercado.",
  "Negociación de cheques de pago diferido, facturas de crédito electrónicas, cheques y pagarés."
]

export const accountsImages = [
  require("../images/group-55.svg").default,
  require("../images/juridicas.svg").default,
  require("../images/fisica.svg").default
]

export const accountsTextParagraphs = [
  {
    title: "Personalmente en nuestras oficinas",
    image: require("../images/oficinas.png").default,
    paragraph: "Podes acercarte a nuestra oficina comercial en donde uno de nuestros representantes te guiará en el proceso de apertura.",
    buttonText: "UBICACIÓN",
    buttonLink: "https://goo.gl/maps/7GbgpVQnK9F4H3dy6"
  },
  {
    title: "Personas Físicas",
    image: require("../images/fisicas.png").default,
    paragraph: "A través del siguiente link podrás acceder a nuestra plataforma de apertura de cuentas digital. Es sólo para Personas Físicas.",
    buttonText: "ABRÍ TU CUENTA ONLINE",
    buttonLink: "https://operar.winvest.ar/ObFisicas"
  },
  {
    title: "Personas Jurídicas",
    image: require("../images/juridicas.png").default,
    paragraph: "A través del siguiente link podrás acceder a nuestra plataforma de apertura de cuentas digital. Es sólo para Personas Jurídicas.",
    buttonText: "ABRÍ TU CUENTA ONLINE",
    buttonLink: "https://operar.winvest.ar/Objuridicas"
  }
];

export const productsData = [
  {
    title: "Acciones",
    paragraph: "Las acciones son títulos emitidos por una empresa que representa una porción de su capital social. Al invertir en acciones, el inversor busca obtener principalmente una ganancia a través de la apreciación de su valor. Adicionalmente, puede obtener una ganancia por medio del cobro de dividendos, en el caso de que la compañía decida distribuir la ganancia del ejercicio entre sus accionistas."
  },
  {
    title: "Opciones",
    paragraph: "Las opciones son instrumentos financieros que implican derivados basados en el valor de activos subyacentes. Un contrato de opciones, ya sea de compra (call) o venta (put), ofrece al tenedor el derecho de comprar o vender el activo subyacente a una fecha determinada y a un precio previamente establecido."
  },
  {
    title: "ADRs",
    paragraph: "Certificados emitidos por un banco comercial de Estados Unidos, para ser transados en el mercado de valores de EE. UU., que representan una determinada cantidad de acciones de una compañía extranjera. Por ejemplo, un inversor puede negociar acciones del Grupo Galicia en el mercado de Estados Unidos a través de la compra de un ADR."
  },
  {
    title: "Cedears",
    paragraph: "Son títulos que cotizan en el mercado argentino y representan acciones de empresas no listadas en Argentina o fondos transables que siguen la cotización de un índice. Por medio de este mecanismo, un inversor local puede comprar acciones de una empresa extranjera que cotiza fuera del país."
  },
  {
    title: "Bonos",
    paragraph: "Un Bono es un instrumento de deuda emitido por entidades públicas o privadas. Según las condiciones de emisión, pueden pagar tasa fija o variable, y su capital puede ser reintegrado en cuotas o totalmente al vencimiento."
  },
  {
    title: "Dólar MEP",
    paragraph: "El Dólar MEP o Dólar Bolsa es aquel tipo de cambio resultante de una operación que consiste en la compra de un instrumento en pesos y su posterior venta en dolares estadounidenses."
  },
  {
    title: "Futuros",
    paragraph: "Un contrato futuro es un acuerdo para comprar o vender un activo a un precio fijo en un instante determinado del futuro. La liquidación del mismo puede ser con entrega del activo o mediante diferencias diarias de efectivo."
  },
  {
    title: "Cauciones Bursátiles",
    paragraph: "La caución bursátil es una operación financiera en la cual un inversor toma prestado o coloca fondos (en pesos o dólares) en el mercado a una tasa de interés determinada. Se opera en plazos de 1 y 120 días hábiles y está garantizada por valores negociables."
  },
  {
    title: "Fondos Comunes de Inversión",
    paragraph: "Un Fondo Común de Inversión es un vehículo cuyas cuota partes son suscriptas por inversores que tienen los mismos objetivos de rentabilidad y riesgo respecto a las inversiones que realizan. Los fondos ingresados son administrados por un grupo de profesionales expertos y son invertidos en distintos instrumentos, locales o internacionales, permitiendo acceder a una mayor diversificación respecto de una inversión puntual."
  },
  {
    title: "Cheques de Pago Diferido",
    paragraph: "En la negociación de Cheques de Pago Diferidos, quien tiene cheques a cobrar en un futuro (por un plazo máximo de 360 días) puede adelantar su cobro vendiéndolos en el mercado, mientras que quien posee fondos líquidos puede comprar estos cheques a cambio de una tasa de interés."
  }
];

export const investorQuizBg = require("../images/man-giving-business-presentation-using-futuristic-digital-pen.png").default;

export const investorQuizData = [
  {
    question: "¿Cuánto conocés el Mercado de Capitales y los instrumentos que se operan?",
    options: [
      "Nada",
      "Un poco",
      "Bastante",
      "Mucho"
    ]
  },
  {
    question: "¿Cuánta experiencia tenés invirtiendo?",
    options: [
      "Ninguna",
      "Mínima",
      "Intermedia",
      "Experto"
    ]
  },
  {
    question: "¿Cuánto tiempo estimás que mantendrás esta inversión?",
    options: [
      "Menos de 2 años",
      "Entre 2 y 4 años",
      "Más de 5 años"
    ]
  },
  {
    question: "Qué buscás al invertir?",
    options: [
      "Mantener el valor de mis ahorros",
      "Ganarle a la inflación",
      "Obtener rendimiento entre la tasa de inflación y hasta 5% más que la misma",
      "Obtener rendimiento mayores a 5% sobre la tasa de inflación, aún si eso implica asumir mayores riesgos"
    ]
  },
  {
    question: "Tenés alguna reserva económica asignada para cubrir imprevistos?",
    options: [
      "No",
      "Sí, unos 3 meses de mis ingresos",
      "Sí, una cantidad elevada"
    ]
  },
  {
    question: "Qué parte de tus ingresos te quedan, una vez hechos todos los pagos/gastos del mes?",
    options: [
      "Una cuarta parte",
      "La tercera parte",
      "La mitad o más"
    ]
  },
  {
    question: "Qué porcentaje de tus ahorros estás dispuesto a invertir en los distintos instrumentos disponibles en el Mercado de Capitales?",
    options: [
      "Hasta una cuarta parte",
      "De una cuarta parte a la mitad",
      "Más de la mitad"
    ]
  },
  {
    question: "Si al recibir información de tus inversiones, observás una baja importante en el valor de uno de tus activos ¿Qué proporción de tus inversiones mantendrías?",
    options: [
      "Vendo todo y me retiro",
      "Rescato parte de la inversión y el resto lo asigno a productos de menor riesgo",
      "Mi estrategia no varía, ya que creo que para obtener rentabilidades superiores, existe la posibilidad de que haya resultados negativos transitorios",
      "Sumo más capital para comprar el producto a la baja"
    ]
  },
  {
    question: "Tu perfil es:"
  }
]

export const linksAndImages = [
  { link: "https://www.argentina.gob.ar/cnv", image: require("../images/CNV.svg").default },
  { link: "https://www.byma.com.ar/en/homepage/", image: require("../images/BYMA.svg").default },
  { link: "https://www.bcra.gob.ar/", image: require("../images/BANCOCENTRAL.svg").default },
  { link: "https://www.labolsa.com.ar/", image: require("../images/BCBA.svg").default },
  { link: "https://www.argentina.gob.ar/economia", image: require("../images/MINISTERIODEECONOMIA.svg").default },
];

export const clockIcon = require("../images/reloj.svg").default;
export const clockIconWhite = require("../images/reloj-blanco.svg").default;

export const placeholderTeamImg = require("../images/placeholder_team.png").default;

export const contactImg1 = require("../images/Contactanos.png").default;
export const contactImg2 = require("../images/CONTACTO.png").default;

// Email data
export const contactEmail = "info@lebsa.com.ar";

export const primaryColor = "#323B98";
export const opacity = 1;
export const secondaryColor = "white";
export const highlightsBackgroundColor = "rgba(227,126,140,1)";

export const headerLogo = require("../images/LOGO.svg").default;
export const largeLogo = require("../images/Large_Logo.svg").default;
