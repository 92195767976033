import React, { useEffect, useState } from "react";
import axios from "axios";
import { Row } from "react-bootstrap";
import HeaderWithOptionalImage from "./HeaderWithOptionalImage";
import ReportsCarousel from "./ReportsCarousel";
import SloganRow from "./SloganRow";
import MonthlyCarousels from "./MonthlyCarousels";
import * as Constants from "../utils/constants";

const Reports = () => {
  const toDate = (el: string): any => new Date(el);
  const formatYearMonth = (date: string) => {
    const dateArr = date.split("-");
    return `${dateArr[0]}${dateArr[1]}`
  }
  const [reportsList, setReportsList] = useState([]);
  const [reportsForMainCarousel, setReportsForMainCarousel] = useState([]);
  useEffect(() => {
    const monthStrings = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ];
    const reportsByMonth: any = {};
    const reportsFilteredByCategory: any = {
      "top stories": [],
      "weekly outlook": [],
      "monthly outlook": [],
    };

    axios.get(Constants.getReportsListURL).then((response) => {

      response.data.sort((a: any, b: any) => toDate(b.date) - toDate(a.date));

      response.data.sort((a: any, b: any) => {
        const order = ['Daily Outlook', 'Top Stories', 'Weekly Outlook', 'Monthly Outlook'];
        return order.indexOf(a.category) - order.indexOf(b.category);
      });

      response.data.forEach((singleReport: any) => {
        let reportCategoryNormalized = singleReport.category.toLowerCase();
        if (reportCategoryNormalized === 'daily outlook')
          reportCategoryNormalized = 'top stories';
        if (reportsFilteredByCategory[reportCategoryNormalized] && reportsFilteredByCategory[reportCategoryNormalized].length < 1) { // Get 3 most recent reports per category
          reportsFilteredByCategory[reportCategoryNormalized].push(singleReport);
        }

        const repDateArr: string = singleReport.date.split("T");
        singleReport.yearMonth = formatYearMonth(singleReport.date);
        singleReport.year = singleReport.date.split("-")[0];
        singleReport.month = singleReport.date.split("-")[1];
        singleReport.date = repDateArr[0];
        // singleReport.title = `${i + 1} - ${singleReport.category}`;
        if (singleReport.category.toLowerCase() === "top stories" || singleReport.category.toLowerCase() === "daily outlook") {
          singleReport.title = 'DIARIO';
        } else {
          if (singleReport.category.toLowerCase() === "weekly outlook") {
            singleReport.title = 'SEMANAL';
          } else {
            singleReport.title = 'MENSUAL';
          }
        }

        singleReport.description = singleReport.intro;
        singleReport.lastUpdate = `${repDateArr[0]} ${repDateArr[1].split(".")[0]}`

        if (reportsByMonth[singleReport.yearMonth]) {
          reportsByMonth[singleReport.yearMonth].push(singleReport);
        } else {
          reportsByMonth[singleReport.yearMonth] = [singleReport];
        }
      })
      const yearMonths: string[] = Object.keys(reportsByMonth);
      yearMonths.sort((a: any, b: any) => b - a);
      let reportsByMonthArr: any = [];
      yearMonths.forEach((yearMonth: string) => {
        reportsByMonthArr.push({
          month: `${monthStrings[parseInt(reportsByMonth[yearMonth][0].month) - 1]} ${reportsByMonth[yearMonth][0].year}`,
          data: reportsByMonth[yearMonth]
        });
      });

      // Format reports by category:
      const sortedCategories = Object.keys(reportsFilteredByCategory).sort((a, b) => {
        const dateA = new Date(reportsFilteredByCategory[a][0].date);
        const dateB = new Date(reportsFilteredByCategory[b][0].date);
        return dateB.getTime() - dateA.getTime();
      });
      const sortedReportsFilteredByCategory: any = {};
      sortedCategories.forEach((category) => {
        sortedReportsFilteredByCategory[category] = reportsFilteredByCategory[category];
      });

      let reportsForMainCarousel: any = [];
      Object.keys(sortedReportsFilteredByCategory).forEach((reportCategory: any) => {
        reportsForMainCarousel.push(...sortedReportsFilteredByCategory[reportCategory]);
      });

      reportsForMainCarousel.sort((a: any, b: any) => {
        const order = ['Daily Outlook', 'Top Stories', 'Weekly Outlook', 'Monthly Outlook'];
        return order.indexOf(a.category) - order.indexOf(b.category);
      });

      // Add images to each story:
      reportsForMainCarousel.forEach((report: any) => {
        if (report.category.toLowerCase() === "top stories" || report.category.toLowerCase() === "daily outlook") {
          report.img = require("../images/TOPSTORIES_2.png").default
        } else if (report.category.toLowerCase() === "weekly outlook") {
          report.img = require("../images/monthly_1.png").default
        } else {
          report.img = require("../images/Weekly_1.png").default
        }
      });

      setReportsList(reportsByMonthArr);
      setReportsForMainCarousel(reportsForMainCarousel)
    });
  }, []);
  return (
    <>
      <HeaderWithOptionalImage title="Informes" bgColor="#EDEDED" />
      <Row>
        <ReportsCarousel mainCarouselData={reportsForMainCarousel} />
      </Row>
      <MonthlyCarousels monthlyData={reportsList} />
      <SloganRow />
    </>
  )
}

export default Reports;