import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import * as Constants from "../utils/constants";
import HeaderWithOptionalImage from "./HeaderWithOptionalImage";
import SloganRow from "./SloganRow";

const Links = () => {
  return (
    <div style={{ backgroundColor: "#FAFAFA" }}>
      <HeaderWithOptionalImage title="Links útiles" bgColor="#FAFAFA" />
      <Row className="links-row" style={{ margin: "2rem 10rem", textAlign: "left", backgroundColor: "#FAFAFA" }}>
        {Constants.linksAndImages.map((linkAndImage: any, i: number) => (
          <Col style={{ padding: "1rem" }} key={i}>
            <a href={linkAndImage.link} target="_blank" rel="noopener noreferrer">
              <Image src={linkAndImage.image} alt={linkAndImage.image} style={{ height: "109px" }} />
            </a>
          </Col>
        ))}
      </Row>
      <SloganRow />
    </div>
  )
}

export default Links;