import React from "react";
import HeaderWithOptionalImage from "./HeaderWithOptionalImage";
import SloganRow from "./SloganRow";
import Row from "react-bootstrap/Row";

const PoliticaDePrivacidad = () => {
  return (
    <>
      <HeaderWithOptionalImage title="Política de Privacidad" bgColor="#EDEDED" />
      <Row className="container-vertically-centered" style={{ padding: "3rem 0", color: "#323B98", backgroundColor: "rgba(237, 237, 237, 1)" }}>
        <div className="custom-long-text-container" style={{ fontFamily: "Exo2Regular", fontSize: "16px", width: "60%", display: "block", textAlign: "left", margin: "auto" }}>
          <p>
            La Compañía toma precauciones razonables para guardar en forma segura toda información obtenida de visitantes en línea contra accesos y usos no autorizados. Asimismo, revisa periódicamente sus medidas de seguridad.
          </p><p>
            No envíe ninguna información confidencial o de su propiedad a la Compañía a través del Sitio. No existe ninguna relación confidencial entre usted y la Compañía mediante la transmisión de información a la Compañía. Cualquier información que reciba la Compañía a través del Sitio que no sea información de identidad personal es considerada no confidencial. La Compañía usa la información de identidad personal solo para procesar una solicitud de información suya o para promocionar nuestros productos y servicios.
          </p><p>
            Al transmitir información a la Compañía a través de este Sitio o cualquier otro medio electrónico, usted otorga a la Compañía la licencia irrevocable e irrestricta para usar, reproducir, exhibir, modificar, distribuir y usar dicha información. La Compañía tiene derecho de usar la información, incluyendo cualquier know-how, técnicas o ideas contenidas en ella libre de costos para cualquier finalidad.
          </p>
        </div>
      </Row>
      <SloganRow />
    </>
  )
}

export default PoliticaDePrivacidad;