import React from 'react';
import { useRouteMatch } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";

const RouterBreadcrumbs = (props: { bgColor: any, paddingTop: string }) => {
  const colsStyle = {
    color: "black",
    paddingLeft: 0,
    paddingRight: 0,
    fontFamily: "Exo2Regular",
    fontSize: "18px"
  }
  const { path } = useRouteMatch();
  const pathChunks = path.split("/");
  return (
    <Container className='custom-breadcrumbs' fluid style={{ paddingTop: props.paddingTop, backgroundColor: props.bgColor }}>
      <Row className='justify-content-left' style={{ textAlign: "left", paddingLeft: "2rem" }}>
        <Col xs="auto">
          <Link
            className="nav-link"
            to="/"
            style={colsStyle}
          >
            Home
          </Link>
        </Col>
        {pathChunks[1] &&
          <>
            <Col xs="auto" className="nav-link" style={colsStyle}>
              {">"}
            </Col>
            <Col xs="auto">
              <Link
                className="nav-link"
                to={`/${pathChunks[1]}`}
                style={colsStyle}
              >
                {pathChunks[1].split("_").map((categoryWord: string) => categoryWord.charAt(0).toUpperCase() + categoryWord.slice(1)).join(" ")}
              </Link>
            </Col>
          </>
        }
        {pathChunks[2] &&
          <>
            <Col xs="auto" className="nav-link" style={colsStyle}>
              {">"}
            </Col>
            <Col xs="auto">
              <Link
                className="nav-link"
                to={`/${pathChunks[1]}/${pathChunks[2]}`}
                style={colsStyle}
              >
                {pathChunks[2].split("_").map((categoryWord: string) => categoryWord.charAt(0).toUpperCase() + categoryWord.slice(1)).join(" ")}
              </Link>
            </Col>
          </>
        }
      </Row>
    </Container>
  );
}

export default RouterBreadcrumbs;