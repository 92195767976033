const breakpointsHelper = (windowInnerWidth: number, sortedElements: any) => {
  if (windowInnerWidth < 600) {
    return sortedElements[0];
  } else if (windowInnerWidth < 768) {
    return sortedElements[1];
  } else if (windowInnerWidth < 992) {
    return sortedElements[2];
  } else if (windowInnerWidth < 1200) {
    return sortedElements[3];
  } else {
    return sortedElements[4];
  }
};

export default breakpointsHelper;
