import React from "react";
import "antd/dist/antd.css";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import * as Constants from "../utils/constants";
import SloganRow from "./SloganRow";
import HeaderWithOptionalImage from "./HeaderWithOptionalImage";

const About = () => {
  return (
    <>
      <HeaderWithOptionalImage title="Sobre Winvest" bgColor="#EDEDED" paragraph="Somos un equipo de asesores con amplia experiencia y de larga trayectoria en el manejo de inversiones financieras. A través de nuestra ALyC Longo Elia Bursátil S.A., brindamos un alto nivel de servicio a nuestros clientes con atención personalizada, asesorando a privados, compañías e instituciones financieras que operan en el Mercado de Capitales de la República Argentina.//Longo Elia Bursátil S.A. (LEBSA) nació en 1979 como Alberto Longo Elia, Agente de Bolsa. A partir de 1992 se formó Longo Elia Bursátil Sociedad de Bolsa S.A. y en 2014 se inscribió como Agente de Liquidación y Compensación (ALyC) propio, registrado bajo el Nº 150 en la Comisión Nacional de Valores (CNV) y Agente miembro del Merval bajo el Nº 220.//Desde 2019 brindamos asesoramiento a nuestros clientes en el Mercado de Capitales internacional, a través del Gestor de Portafolios Winvest S.A., radicado en la República Oriental de Uruguay." paragraphWidth="75%" />
      <Row style={{ color: "#323B98", backgroundColor: "#FFF", padding: "3rem 6rem" }}>
        <Col style={{ textAlign: "left", fontFamily: "Exo2SemiBold", fontSize: "32px" }}>
          Nuestra Filosofía
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} style={{ padding: 0, aspectRatio: "1/1" }}>
          <Image style={{ aspectRatio: "1/1", width: "200%" }} fluid src={Constants.handshake} alt={Constants.handshake} />
        </Col>
        <Col xs={12} md={6} style={{ padding: 0, aspectRatio: "1/1" }}>
          <Image style={{ aspectRatio: "1/1" }} fluid src={Constants.pilares} alt={Constants.pilares} />
        </Col>
      </Row>
      <Row className="custom-inverted-row">
        <Col xs={12} md={6} style={{ padding: 0, aspectRatio: "1/1" }}>
          <Image style={{ aspectRatio: "1/1" }} fluid src={Constants.valores} alt={Constants.valores} />
        </Col>
        <Col xs={12} md={6} style={{ padding: 0, aspectRatio: "1/1" }}>
          <Image style={{ aspectRatio: "1/1", width: "200%" }} fluid src={Constants.typing} alt={Constants.typing} />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} style={{ padding: 0, aspectRatio: "1/1" }}>
          <Image style={{ aspectRatio: "1/1", width: "200%" }} fluid src={Constants.showing} alt={Constants.showing} />
        </Col>
        <Col xs={12} md={6} style={{ padding: 0, aspectRatio: "1/1" }}>
          <Image style={{ aspectRatio: "1/1" }} fluid src={Constants.vision} alt={Constants.vision} />
        </Col>
      </Row>
      <Row className="container-vertically-centered" style={{ padding: "5rem 0", color: "rgba(0, 197, 250, 1)" }}>
        <div className="custom-quote-paragraph" style={{ textAlign: "left", fontFamily: "utopiaSemiBold", fontSize: "32px", width: "60%", marginLeft: "6rem", lineHeight: "2.5rem", color: "#323B98" }}>
          Durante nuestra larga trayectoria, brindamos un alto nivel de servicio a diversos clientes a través de una atención personalizada asesorando a privados, compañías e instituciones financieras.
        </div>
      </Row>
      <Row className="custom-row" style={{ color: "rgba(50, 59, 152, 1)", padding: "3rem 6rem" }}>
        <Col style={{ textAlign: "left", fontFamily: "Exo2SemiBold", fontSize: "32px" }}>
          Nuestro Directorio
        </Col>
      </Row>
      <Row className="custom-about-row" style={{ backgroundColor: "transparent", color: "rgba(59, 59, 58, 1)", padding: "1rem 6rem 3rem 6rem", backgroundImage: `url(${Constants.ourTeamTexture})`, backgroundRepeat: "no-repeat", backgroundSize: "auto" }}>
        <Col xs={6} lg={4}>
          <div style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            overflow: "hidden",
            paddingLeft: "2rem"
          }}>
            <Image
              fluid
              src={require("../images/associates/Gonzalo Longo Elia.jpg").default}
              alt={require("../images/associates/Gonzalo Longo Elia.jpg").default}
              style={{
                flexShrink: 0,
                width: "200px"
              }}
            />
          </div>
          <div style={{ textAlign: "left", padding: "2rem 2rem 2rem 2rem", fontFamily: "Exo2Regular", fontSize: "16px" }}>
            <div style={{ fontFamily: "utopiaSemibold", fontSize: "24px", minHeight: "2rem" }}>
              Gonzalo Longo Elía
            </div>
            <div style={{ fontFamily: "Exo2Regular", fontSize: "19px", minHeight: "3rem" }}>
              Presidente - Socio
            </div>
            <div style={{ fontFamily: "Exo2Regular", fontSize: "16px" }}>
              Estudió Administración de Empresas en la Universidad Católica Argentina. Es Asesor Financiero Idóneo inscripto en la CNV desde 2008. También es Operador del Mercado Bursátil y de ROFEX. Trabajó como analista en el banco HSBC y en el Citibank. Por otra parte, fue jugador profesional de rugby, destacándose durante 10 años en la Selección Argentina y durante 7 años en equipos de Francia. En 2018 se convirtió en Socio Accionista de LEBSA. Actualmente, es Presidente y responsable de la relación comercial con clientes institucionales y privados.
            </div>
          </div>
        </Col>
        <Col xs={6} lg={4}>
          <div style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            overflow: "hidden",
            paddingLeft: "2rem"
          }}>
            <Image
              fluid
              src={require("../images/associates/Pablo Longo Elia.jpg").default}
              alt={require("../images/associates/Pablo Longo Elia.jpg").default}
              style={{
                flexShrink: 0,
                width: "200px"
              }}
            />
          </div>
          <div style={{ textAlign: "left", padding: "2rem 2rem 2rem 2rem", fontFamily: "Exo2Regular", fontSize: "16px" }}>
            <div style={{ fontFamily: "utopiaSemibold", fontSize: "24px", minHeight: "2rem" }}>
              Pablo Longo Elía
            </div>
            <div style={{ fontFamily: "Exo2Regular", fontSize: "19px", minHeight: "3rem" }}>
              Vicepresidente - Socio
            </div>
            <div style={{ fontFamily: "Exo2Regular", fontSize: "16px" }}>
              Licenciado en Economía por la Universidad de San Andrés, Magister en Finanzas por la Universidad Torcuato Di Tella y Asesor Financiero Idóneo inscripto en la CNV. Tiene 29 años de experiencia trabajando en instituciones financieras y 23 años en el manejo de inversiones de clientes privados. Fue analista financiero en Chase Manhattan Bank en New York durante 4 años y Asesor de Inversiones durante 10 años en Wells Fargo Advisors (ex Wachovia Securities y Prudential Financial). En 2010 continuó su carrera como Asesor Financiero Independiente, fundando LE Capital Advisors donde se desempeña actualmente como Director Ejecutivo. También es Presidente de Winvest S.A, y Vicepresidente de LEBSA.
            </div>
          </div>
        </Col>
        <Col xs={6} lg={4}>
          <div style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            overflow: "hidden",
            paddingLeft: "2rem"
          }}>
            <Image
              fluid
              src={require("../images/associates/Alberto Longo Elia.jpg").default}
              alt={require("../images/associates/Alberto Longo Elia.jpg").default}
              style={{
                flexShrink: 0,
                width: "200px"
              }}
            />
          </div>
          <div style={{ textAlign: "left", padding: "2rem 2rem 2rem 2rem", fontFamily: "Exo2Regular", fontSize: "16px" }}>
            <div style={{ fontFamily: "utopiaSemibold", fontSize: "24px", minHeight: "2rem" }}>
              Alberto Longo Elía
            </div>
            <div style={{ fontFamily: "Exo2Regular", fontSize: "19px", minHeight: "3rem" }}>
              Director - Socio
            </div>
            <div style={{ fontFamily: "Exo2Regular", fontSize: "16px" }}>
              Con más de 45 años de experiencia en la Bolsa de Comercio de Buenos Aires, y más de 10 años en la Bolsa de Cereales de Buenos Aires, se destaca por ser un participante activo y relevante en el desarrollo del mercado de capitales argentino. Ocupó el cargo de Director Titular de la Caja de Valores en 1994; Consejero Titular de la Bolsa de Comercio de Buenos Aires entre 1998 y 2000; y Presidente del Consejo de Vigilancia del Mercado de Valores de Buenos Aires entre 2000 y 2001. Fue Presidente del Grupo Financiero Valores hasta 2021.
            </div>
          </div>
        </Col>
      </Row>
      <Row className="custom-about-row" style={{ backgroundColor: "transparent", color: "rgba(59, 59, 58, 1)", padding: "1rem 6rem 3rem 6rem" }}>
      </Row>

      <Row className="custom-row" style={{ color: "#323B98", backgroundColor: "#E6E6E6", padding: "3rem 6rem" }}>
        <Col style={{ textAlign: "left", fontFamily: "Exo2SemiBold", fontSize: "32px" }}>
          Nuestros Asociados
        </Col>
      </Row>
      <Row className="custom-about-row" style={{ backgroundColor: "#E6E6E6", color: "#3B3B3A", padding: "1rem 6rem 3rem 6rem" }}>
        <Col xs={6} lg={4}>
          <div style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            overflow: "hidden",
            paddingLeft: "2rem"
          }}>
            <Image
              fluid
              src={require("../images/associates/Gustavo Lozada Echenique.jpg").default}
              alt={require("../images/associates/Gustavo Lozada Echenique.jpg").default}
              style={{
                flexShrink: 0,
                width: "200px"
              }}
            />
          </div>
          <div style={{ textAlign: "left", padding: "2rem 2rem 2rem 2rem", fontFamily: "Exo2Regular", fontSize: "16px" }}>
            <div style={{ fontFamily: "utopiaSemibold", fontSize: "24px", minHeight: "2rem" }}>
              Gustavo Lozada Echenique
            </div>
            <div style={{ fontFamily: "Exo2Regular", fontSize: "19px", minHeight: "3rem" }}>
              Socio - Agente Productor
            </div>
            <div style={{ fontFamily: "Exo2Regular", fontSize: "16px" }}>
              Técnico en Comercio Internacional por la Universidad de Belgrano y Asesor Financiero Idóneo en Mercado de Capitales inscripto en la CNV. Durante los últimos 30 años, trabajó en diferentes compañías dentro del mercado financiero, como Bank Boston y Boston Securities, donde se desempeñó como Asesor de Inversiones. En 2018, se incorporó a LEBSA como socio y Productor Bursátil, y desde 2019 es socio de Winvest S.A.
            </div>
          </div>
        </Col>
        <Col xs={6} lg={4}>
          <div style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            overflow: "hidden",
            paddingLeft: "2rem"
          }}>
            <Image
              fluid
              src={require("../images/associates/Martin Cullen.jpg").default}
              alt={require("../images/associates/Martin Cullen.jpg").default}
              style={{
                flexShrink: 0,
                width: "200px"
              }}
            />
          </div>
          <div style={{ textAlign: "left", padding: "2rem 2rem 2rem 2rem", fontFamily: "Exo2Regular", fontSize: "16px" }}>
            <div style={{ fontFamily: "utopiaSemibold", fontSize: "24px", minHeight: "2rem" }}>
              Martin Cullen
            </div>
            <div style={{ fontFamily: "Exo2Regular", fontSize: "19px", minHeight: "3rem" }}>
              Socio - Agente Productor
            </div>
            <div style={{ fontFamily: "Exo2Regular", fontSize: "16px" }}>
              Asesor Idóneo en Mercado de Capitales inscripto en la CNV y Técnico en Administración y Finanzas por la Universidad Austral. Fue operador en el Mercado a Término de Cereales y trabajo en diferentes compañías de la industria como Banco Privado de Inversiones, HSBC y Wells Fargo, desempeñándose como Asesor Financiero de Banca Privada. En 2010 comenzó a trabajar como Asesor Financiero Independiente. En 2018, se incorporó a LEBSA como socio y Productor Bursátil, y desde 2019 es socio de Winvest S.A.
            </div>
          </div>
        </Col>
        <Col xs={6} lg={4}>
          <div style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            overflow: "hidden",
            paddingLeft: "2rem"
          }}>
            <Image
              fluid
              src={require("../images/associates/Juan Frias.jpg").default}
              alt={require("../images/associates/Juan Frias.jpg").default}
              style={{
                flexShrink: 0,
                width: "200px"
              }}
            />
          </div>
          <div style={{ textAlign: "left", padding: "2rem 2rem 2rem 2rem", fontFamily: "Exo2Regular", fontSize: "16px" }}>
            <div style={{ fontFamily: "utopiaSemibold", fontSize: "24px", minHeight: "2rem" }}>
              Juan Frias
            </div>
            <div style={{ fontFamily: "Exo2Regular", fontSize: "19px", minHeight: "3rem" }}>
              Socio - Agente Productor
            </div>
            <div style={{ fontFamily: "Exo2Regular", fontSize: "16px" }}>
              Es licenciado en Administración de Empresas por la Universidad Católica Argentina y Agente Productor Idóneo inscripto en la CNV desde 2016. Tiene 25 años de experiencia en el manejo de Inversiones Financieras y Wealth Management. Trabajó en Wells Fargo Advisors, Wachovia Securities y Prudential Securities, entre otras compañías del sector financiero. En 2018, se incorporó a LEBSA como socio, y desde 2019 es socio de Winvest S.A.
            </div>
          </div>
        </Col>
        <Col xs={6} lg={4}>
          <div style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            overflow: "hidden",
            paddingLeft: "2rem"
          }}>
            <Image
              fluid
              src={require("../images/associates/Ignacio Llerena.jpg").default}
              alt={require("../images/associates/Ignacio Llerena.jpg").default}
              style={{
                flexShrink: 0,
                width: "200px"
              }}
            />
          </div>
          <div style={{ textAlign: "left", padding: "2rem 2rem 2rem 2rem", fontFamily: "Exo2Regular", fontSize: "16px" }}>
            <div style={{ fontFamily: "utopiaSemibold", fontSize: "24px", minHeight: "2rem" }}>
              Ignacio Llerena
            </div>
            <div style={{ fontFamily: "Exo2Regular", fontSize: "19px", minHeight: "3rem" }}>
              Socio - Agente Productor
            </div>
            <div style={{ fontFamily: "Exo2Regular", fontSize: "16px" }}>
              Licenciado en Economía por la Universidad Católica Argentina (UCA) y Asesor Financiero Idóneo inscripto en la Comisión Nacional de Valores. Cuenta con 39 años de experiencia en entidades financieras de primer nivel. Durante el período 1998 - 2010 fue Asesor de Inversiones en Wells Fargo Advisors (antes Wachovia Securities y Prudential Financial). En 2010, comenzó con la consultoría independiente brindando asesoramiento económico - financiero. En 2018, se incorporó a LEBSA como socio y Productor Bursátil. Desde 2019, es socio de Winvest S.A.
            </div>
          </div>
        </Col>
        <Col xs={6} lg={4}>
          <div style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            overflow: "hidden",
            paddingLeft: "2rem"
          }}>
            <Image
              fluid
              src={require("../images/associates/SEBASTIAN PISTONE.jpg").default}
              alt={require("../images/associates/SEBASTIAN PISTONE.jpg").default}
              style={{
                flexShrink: 0,
                width: "200px"
              }}
            />
          </div>
          <div style={{ textAlign: "left", padding: "2rem 2rem 2rem 2rem", fontFamily: "Exo2Regular", fontSize: "16px" }}>
            <div style={{ fontFamily: "utopiaSemibold", fontSize: "24px", minHeight: "2rem" }}>
              Sebastián Pistone
            </div>
            <div style={{ fontFamily: "Exo2Regular", fontSize: "19px", minHeight: "3rem" }}>
              Socio - Agente Productor
            </div>
            <div style={{ fontFamily: "Exo2Regular", fontSize: "16px" }}>
              Es Asesor Financiero Idóneo registrado en la CNV. Tiene 35 años de experiencia trabajando en instituciones financieras y 26 años en el asesoramiento de inversiones a clientes privados, tanto en la banca local como internacional. Trabajó en Banco Río como Gerente Comercial y en Banco Santander como Team Leader Comercial, brindando asesoramiento financiero internacional. Además, se desempeñó como Asesor Financiero en Prudential Securities, Wachovia Securities, Wells Fargo Securities. En 2010, comenzó a trabajar como Asesor Independiente. En 2018, se incorporó a LEBSA como socio y Productor Bursátil, y desde 2019 es socio de Winvest S.A.
            </div>
          </div>
        </Col>
        <Col xs={6} lg={4}>
          <div style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            overflow: "hidden",
            paddingLeft: "2rem"
          }}>
            <Image
              fluid
              src={require("../images/associates/ALEJANDRO LANUSSE.jpg").default}
              alt={require("../images/associates/ALEJANDRO LANUSSE.jpg").default}
              style={{
                flexShrink: 0,
                width: "200px"
              }}
            />
          </div>
          <div style={{ textAlign: "left", padding: "2rem 2rem 2rem 2rem", fontFamily: "Exo2Regular", fontSize: "16px" }}>
            <div style={{ fontFamily: "utopiaSemibold", fontSize: "24px", minHeight: "2rem" }}>
              Alejandro Lanusse
            </div>
            <div style={{ fontFamily: "Exo2Regular", fontSize: "19px", minHeight: "3rem" }}>
              Socio - Agente Productor
            </div>
            <div style={{ fontFamily: "Exo2Regular", fontSize: "16px" }}>
              Es Asesor Financiero Idóneo registrado en la CNV. Tiene más de 29 años de experiencia en el Mercado Capitales, trabajando como asesor financiero de Banca Privada en distintas compañías como BBVA Banco Francés y Wells Fargo (ex Prudential Securities y posteriormente Wachovia Securities). Además, fue Corredor y Operador del Mercado a Término de Cereales y Operador de MERFOX. Desde 2010 se desempeña como Consultor Financiero Independiente. En 2018, se incorporó a LEBSA como socio y Productor Bursátil, y desde 2019 es socio de Winvest S.A.
            </div>
          </div>
        </Col>
        <Col xs={6} lg={4}>
          <div style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            overflow: "hidden",
            paddingLeft: "2rem"
          }}>
            <Image
              fluid
              src={require("../images/associates/GONZALO ARAYA.jpg").default}
              alt={require("../images/associates/GONZALO ARAYA.jpg").default}
              style={{
                flexShrink: 0,
                width: "200px"
              }}
            />
          </div>
          <div style={{ textAlign: "left", padding: "2rem 2rem 2rem 2rem", fontFamily: "Exo2Regular", fontSize: "16px" }}>
            <div style={{ fontFamily: "utopiaSemibold", fontSize: "24px", minHeight: "2rem" }}>
              Gonzalo Araya
            </div>
            <div style={{ fontFamily: "Exo2Regular", fontSize: "19px", minHeight: "3rem" }}>
              Socio - Agente Productor
            </div>
            <div style={{ fontFamily: "Exo2Regular", fontSize: "16px" }}>
              Asesor Financiero Idóneo Inscripto en la CNV. Trabajó en Merrill Lynch, Pierce, Fenner & Smith de Argentina como Asesor financiero, responsable del manejo de cartera de inversión de clientes individuales e institucionales. Fue socio de la firma Sigma Advisors S.A prestando servicio de Asesoramiento, Dirección y Gestión Empresarial. Desde 2015 es Asesor Financiero Independiente. En 2018, se incorporó a LEBSA como socio y Productor Bursátil y desde 2019 es socio de Winvest S.A.
            </div>
          </div>
        </Col>
        <Col xs={6} lg={4}>
          <div style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            overflow: "hidden",
            paddingLeft: "2rem"
          }}>
            <Image
              fluid
              src={require("../images/associates/RICARDO REVIGLIONO.jpg").default}
              alt={require("../images/associates/RICARDO REVIGLIONO.jpg").default}
              style={{
                flexShrink: 0,
                width: "200px"
              }}
            />
          </div>
          <div style={{ textAlign: "left", padding: "2rem 2rem 2rem 2rem", fontFamily: "Exo2Regular", fontSize: "16px" }}>
            <div style={{ fontFamily: "utopiaSemibold", fontSize: "24px", minHeight: "2rem" }}>
              Ricardo Revigliono
            </div>
            <div style={{ fontFamily: "Exo2Regular", fontSize: "19px", minHeight: "3rem" }}>
              Socio - Agente Productor
            </div>
            <div style={{ fontFamily: "Exo2Regular", fontSize: "16px" }}>
              Estudió Administración de Empresas y Contador Público en la Universidad Católica Argentina. A su vez, está inscripto en la CNV como Agente Productor de Longo Elia Bursátil. Cuenta con una experiencia de más de 30 años en el sector financiero, enfocado en el manejo y asesoramiento de inversiones a clientes privados. Trabajo en Banco Finansur, Banco Galicia y en Capital Markets SA Sociedad de Bolsa. En 2018, se incorporó a LEBSA como socio y desde 2019 es socio de Winvest S.A.
            </div>
          </div>
        </Col>
        <Col xs={6} lg={4}>
          <div style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            overflow: "hidden",
            paddingLeft: "2rem"
          }}>
            <Image
              fluid
              src={require("../images/associates/IGNACIO LOPEZ ARAMBURU.jpg").default}
              alt={require("../images/associates/IGNACIO LOPEZ ARAMBURU.jpg").default}
              style={{
                flexShrink: 0,
                width: "200px"
              }}
            />
          </div>
          <div style={{ textAlign: "left", padding: "2rem 2rem 2rem 2rem", fontFamily: "Exo2Regular", fontSize: "16px" }}>
            <div style={{ fontFamily: "utopiaSemibold", fontSize: "24px", minHeight: "2rem" }}>
              Ignacio Lopez Aramburu
            </div>
            <div style={{ fontFamily: "Exo2Regular", fontSize: "19px", minHeight: "3rem" }}>
              Socio - Agente Productor
            </div>
            <div style={{ fontFamily: "Exo2Regular", fontSize: "16px" }}>
              Es Licenciado en Administración de Empresas en la Universidad Católica Argentina (UCA) y Asesor Financiero Idóneo inscripto en la CNV. Cuenta con 31 años de carrera en el mercado financiero y bancario, y más de 20 años en el manejo de inversiones financieras de clientes privados. Se desempeñó como Sub Gerente de Productos de Inversión para empresas Bank Boston y como Team Leader Banca Privada en Standard Bank Argentina. Desde 2012 que se dedica al asesoramiento de clientes privados en forma independiente. En 2018 se incorporó a LEBSA como socio y Productor Bursátil, y desde 2019 es socio de Winvest S.A.
            </div>
          </div>
        </Col>
        <Col xs={6} lg={4}>
          <div style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            overflow: "hidden",
            paddingLeft: "2rem"
          }}>
            <Image
              fluid
              src={require("../images/associates/NORBERTO de ELIZALDE.jpg").default}
              alt={require("../images/associates/NORBERTO de ELIZALDE.jpg").default}
              style={{
                flexShrink: 0,
                width: "200px"
              }}
            />
          </div>
          <div style={{ textAlign: "left", padding: "2rem 2rem 2rem 2rem", fontFamily: "Exo2Regular", fontSize: "16px" }}>
            <div style={{ fontFamily: "utopiaSemibold", fontSize: "24px", minHeight: "2rem" }}>
              Norberto de Elizalde
            </div>
            <div style={{ fontFamily: "Exo2Regular", fontSize: "19px", minHeight: "3rem" }}>
              Socio - Agente Productor
            </div>
            <div style={{ fontFamily: "Exo2Regular", fontSize: "16px" }}>
              Licenciado en Administración de Empresas en Boston University School of Management y Asesor Financiero Idóneo registrado en la CNV. Cuenta con 33 años de experiencia en mercados financieros globales. Trabajó en las áreas de Tesorería, Banca Privada y Asset Management de JP Morgan, Bank of América, ING Bank, EFG Bank, Standard Bank y CMA. Fue Director Regional Sud América para Columbia Management, división de fondos de inversión de Bank of América para Clientes. En 2018 se incorporó a LEBSA como socio y Productor Bursátil, y desde 2019 es socio de Winvest S.A.
            </div>
          </div>
        </Col>
        <Col xs={6} lg={4}>
          <div style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            overflow: "hidden",
            paddingLeft: "2rem"
          }}>
            <Image
              fluid
              src={require("../images/associates/Javier Sanchez de La-Puente.jpg").default}
              alt={require("../images/associates/Javier Sanchez de La-Puente.jpg").default}
              style={{
                flexShrink: 0,
                width: "200px"
              }}
            />
          </div>
          <div style={{ textAlign: "left", padding: "2rem 2rem 2rem 2rem", fontFamily: "Exo2Regular", fontSize: "16px" }}>
            <div style={{ fontFamily: "utopiaSemibold", fontSize: "24px", minHeight: "2rem" }}>
              Javier Sanchez de La-Puente
            </div>
            <div style={{ fontFamily: "Exo2Regular", fontSize: "19px", minHeight: "3rem" }}>
              Socio - Agente Productor
            </div>
            <div style={{ fontFamily: "Exo2Regular", fontSize: "16px" }}>
              Asesor financiero Idóneo inscripto en la CNV. Cuenta con 32 años de experiencia trabajando en Bancos, tanto locales como internacionales. Fue Asesor Financiero en Prudential Financial, Wachovia Securities y Wells Fargo donde ocupó el cargo de First Vice President. Desde 2010, se desempeña como asesor independiente. En 2018 se incorporó a LEBSA como socio y Productor Bursátil, y desde 2019 es socio de Winvest S.A.
            </div>
          </div>
        </Col>
        <Col xs={6} lg={4}>
          <div style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            overflow: "hidden",
            paddingLeft: "2rem"
          }}>
            <Image
              fluid
              src={require("../images/associates/DIEGO BULLRICH.jpg").default}
              alt={require("../images/associates/DIEGO BULLRICH.jpg").default}
              style={{
                flexShrink: 0,
                width: "200px"
              }}
            />
          </div>
          <div style={{ textAlign: "left", padding: "2rem 2rem 2rem 2rem", fontFamily: "Exo2Regular", fontSize: "16px" }}>
            <div style={{ fontFamily: "utopiaSemibold", fontSize: "24px", minHeight: "2rem" }}>
              Diego Bullrich
            </div>
            <div style={{ fontFamily: "Exo2Regular", fontSize: "19px", minHeight: "3rem" }}>
              Agente Productor
            </div>
            <div style={{ fontFamily: "Exo2Regular", fontSize: "16px" }}>
              Licenciado en Administración de Empresas por la Universidad Católica Argentina y Asesor Financiero Idóneo registrado en la CNV. Tiene 37 años de experiencia trabajando en el sistema financiero, desempeñándose como asesor en banca privada. Trabajó en compañías como Banco Galicia, ABN AMOR Bank y Banco Santander, Eurocapital SA. En 2018 se incorporó a LEBSA como Productor Bursátil, y desde 2019 es socio de Winvest S.A
            </div>
          </div>
        </Col>
        <Col xs={6} lg={4}>
          <div style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            overflow: "hidden",
            paddingLeft: "2rem"
          }}>
            <Image
              fluid
              src={require("../images/associates/GABRIELA BONAVITA.jpg").default}
              alt={require("../images/associates/GABRIELA BONAVITA.jpg").default}
              style={{
                flexShrink: 0,
                width: "200px"
              }}
            />
          </div>
          <div style={{ textAlign: "left", padding: "2rem 2rem 2rem 2rem", fontFamily: "Exo2Regular", fontSize: "16px" }}>
            <div style={{ fontFamily: "utopiaSemibold", fontSize: "24px", minHeight: "2rem" }}>
              Gabriela Bonavita
            </div>
            <div style={{ fontFamily: "Exo2Regular", fontSize: "19px", minHeight: "3rem" }}>
              Agente Productor
            </div>
            <div style={{ fontFamily: "Exo2Regular", fontSize: "16px" }}>
              Asesora Financiera registrada en la CNV y Agente Productor Bursátil de LEBSA. Tiene 25 años de experiencia asesorando a clientes de Banca Privada. Trabajó durante 12 años en Wells Fargo LLC (ex Prudential Securities y posteriormente Wachovia Securities). Desde 2010, se desempeña como Asesora Independiente, y en el 2019 es asoció a Winvest S.A
            </div>
          </div>
        </Col>
        <Col xs={6} lg={4}>
          <div style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            overflow: "hidden",
            paddingLeft: "2rem"
          }}>
            <Image
              fluid
              src={require("../images/associates/JUAN PABLO LLAMBI.jpg").default}
              alt={require("../images/associates/JUAN PABLO LLAMBI.jpg").default}
              style={{
                flexShrink: 0,
                width: "200px"
              }}
            />
          </div>
          <div style={{ textAlign: "left", padding: "2rem 2rem 2rem 2rem", fontFamily: "Exo2Regular", fontSize: "16px" }}>
            <div style={{ fontFamily: "utopiaSemibold", fontSize: "24px", minHeight: "2rem" }}>
              Juan Pablo Llambi
            </div>
            <div style={{ fontFamily: "Exo2Regular", fontSize: "19px", minHeight: "3rem" }}>
              Agente Productor
            </div>
            <div style={{ fontFamily: "Exo2Regular", fontSize: "16px" }}>
              Licenciado en Administración de Empresas por la Universidad Católica Argentina (UCA) y Asesor Financiero Idóneo registrado en la CNV. Cuenta con 25 años de experiencia trabajando en el sistema financiero como asesor de inversiones. Trabajó en compañías como MBA Banco de Inversiones y Maro Securities Sociedad de Bolsa. Desde 2014, trabaja como Asesor Financiero Independiente prestando servicios a individuos y empresas. En 2018 se incorporó a LEBSA como Productor Bursátil, y desde 2019 es socio de Winvest S.A
            </div>
          </div>
        </Col>
      </Row>

      <Row className="custom-row" style={{ color: "rgba(50, 59, 152, 1)", padding: "3rem 6rem" }}>
        <Col style={{ textAlign: "left", fontFamily: "Exo2SemiBold", fontSize: "32px" }}>
          Nuestro Equipo
        </Col>
      </Row>
      <Row className="custom-about-row" style={{ backgroundColor: "transparent", color: "rgba(59, 59, 58, 1)", padding: "1rem 6rem 3rem 6rem", backgroundImage: `url(${Constants.ourTeamTexture})`, backgroundRepeat: "no-repeat", backgroundSize: "auto" }}>
        <Col xs={6} lg={4}>
          <div style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            overflow: "hidden",
            paddingLeft: "2rem"
          }}>
            <Image
              fluid
              src={require("../images/associates/Dario Martinez.jpg").default}
              alt={require("../images/associates/Dario Martinez.jpg").default}
              style={{
                flexShrink: 0,
                width: "200px"
              }}
            />
          </div>
          <div style={{ textAlign: "left", padding: "2rem 2rem 2rem 2rem", fontFamily: "Exo2Regular", fontSize: "16px" }}>
            <div style={{ fontFamily: "utopiaSemibold", fontSize: "24px", minHeight: "2rem" }}>
              Dario Martinez
            </div>
            <div style={{ fontFamily: "Exo2Regular", fontSize: "19px", minHeight: "3rem" }}>
              Head of Trading Desk
            </div>
          </div>
        </Col>
        <Col xs={6} lg={4}>
          <div style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            overflow: "hidden",
            paddingLeft: "2rem"
          }}>
            <Image
              fluid
              src={require("../images/associates/Juan Rossi.jpg").default}
              alt={require("../images/associates/Juan Rossi.jpg").default}
              style={{
                flexShrink: 0,
                width: "200px"
              }}
            />
          </div>
          <div style={{ textAlign: "left", padding: "2rem 2rem 2rem 2rem", fontFamily: "Exo2Regular", fontSize: "16px" }}>
            <div style={{ fontFamily: "utopiaSemibold", fontSize: "24px", minHeight: "2rem" }}>
              Juan Rossi
            </div>
            <div style={{ fontFamily: "Exo2Regular", fontSize: "19px", minHeight: "3rem" }}>
              Trader Desk
            </div>
          </div>
        </Col>
        <Col xs={6} lg={4}>
          <div style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            overflow: "hidden",
            paddingLeft: "2rem"
          }}>
            <Image
              fluid
              src={require("../images/associates/Roxana Monetti.jpg").default}
              alt={require("../images/associates/Roxana Monetti.jpg").default}
              style={{
                flexShrink: 0,
                width: "200px"
              }}
            />
          </div>
          <div style={{ textAlign: "left", padding: "2rem 2rem 2rem 2rem", fontFamily: "Exo2Regular", fontSize: "16px" }}>
            <div style={{ fontFamily: "utopiaSemibold", fontSize: "24px", minHeight: "2rem" }}>
              Roxana Monetti
            </div>
            <div style={{ fontFamily: "Exo2Regular", fontSize: "19px", minHeight: "3rem" }}>
              Head of Back Office
            </div>
          </div>
        </Col>
        <Col xs={6} lg={4}>
          <div style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            overflow: "hidden",
            paddingLeft: "2rem"
          }}>
            <Image
              fluid
              src={require("../images/associates/Christian Sena.jpg").default}
              alt={require("../images/associates/Christian Sena.jpg").default}
              style={{
                flexShrink: 0,
                width: "200px"
              }}
            />
          </div>
          <div style={{ textAlign: "left", padding: "2rem 2rem 2rem 2rem", fontFamily: "Exo2Regular", fontSize: "16px" }}>
            <div style={{ fontFamily: "utopiaSemibold", fontSize: "24px", minHeight: "2rem" }}>
              Christian Sena
            </div>
            <div style={{ fontFamily: "Exo2Regular", fontSize: "19px", minHeight: "3rem" }}>
              Compliance
            </div>
          </div>
        </Col>
        <Col xs={6} lg={4}>
          <div style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            overflow: "hidden",
            paddingLeft: "2rem"
          }}>
            <Image
              fluid
              src={require("../images/associates/Roxana Terrazas.jpg").default}
              alt={require("../images/associates/Roxana Terrazas.jpg").default}
              style={{
                flexShrink: 0,
                width: "200px"
              }}
            />
          </div>
          <div style={{ textAlign: "left", padding: "2rem 2rem 2rem 2rem", fontFamily: "Exo2Regular", fontSize: "16px" }}>
            <div style={{ fontFamily: "utopiaSemibold", fontSize: "24px", minHeight: "2rem" }}>
              Roxana Terrazas
            </div>
            <div style={{ fontFamily: "Exo2Regular", fontSize: "19px", minHeight: "3rem" }}>
              Back Office
            </div>
          </div>
        </Col>
        <Col xs={6} lg={4}>
          <div style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            overflow: "hidden",
            paddingLeft: "2rem"
          }}>
            <Image
              fluid
              src={require("../images/associates/Fernando Arnal.jpg").default}
              alt={require("../images/associates/Fernando Arnal.jpg").default}
              style={{
                flexShrink: 0,
                width: "200px"
              }}
            />
          </div>
          <div style={{ textAlign: "left", padding: "2rem 2rem 2rem 2rem", fontFamily: "Exo2Regular", fontSize: "16px" }}>
            <div style={{ fontFamily: "utopiaSemibold", fontSize: "24px", minHeight: "2rem" }}>
              Fernando Arnal
            </div>
            <div style={{ fontFamily: "Exo2Regular", fontSize: "19px", minHeight: "3rem" }}>
              Back Office
            </div>
          </div>
        </Col>
      </Row>
      <Row className="custom-about-row" style={{ backgroundColor: "transparent", color: "rgba(59, 59, 58, 1)", padding: "1rem 6rem 3rem 6rem" }}>
      </Row>
      <SloganRow />
    </>
  );
}

export default About;
