import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// Component to handle scroll reset in React routes (avoids keeping the same scroll position when changing routes)
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      // @ts-ignore
      behavior: "instant" // "instant" removes animation. Use "smooth" for scrolling smoothly
    });
  }, [pathname]);
  return null;
}

export default ScrollToTop;