import React, { useState, useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-tooltip/dist/react-tooltip.css";
import { Header } from "../Header";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "../../components/Home";
import About from "../../components/About";
import Alliances from "../Alliances";
import Services from "../Services";
import Accounts from "../Accounts";
import InvestorProfile from "../InvestorProfile";
import Contact from "../Contact";
import Links from "../Links";
import Reports from "../Reports";
import Products from "../Products";
import Asesoramiento from "../Asesoramiento";
import TerminosYCondiciones from "../TerminosYCondiciones";
import SuggestedPortfolios from "../SuggestedPortfolios";
import PoliticaDePrivacidad from "../PoliticaDePrivacidad";
import Empresas from "../Empresas";
// import { Spin } from "antd";
import Footer from "../Footer";
import * as Constants from "../../utils/constants";
import ScrollToTop from "../ScrollToTop";

const componentRegistry: any = {
  Home: Home,
  About: About,
  Alliances: Alliances,
  Services: Services,
  Empresas: Empresas,
  Accounts: Accounts,
  InvestorProfile: InvestorProfile,
  Contact: Contact,
  Links: Links,
  Reports: Reports,
  Asesoramiento: Asesoramiento,
  Products: Products,
  SuggestedPortfolios: SuggestedPortfolios,
  TerminosYCondiciones: TerminosYCondiciones,
  PoliticaDePrivacidad: PoliticaDePrivacidad
};

const App = () => {
  // The back-to-top button is hidden at the beginning
  const [showGoToTopButton, setShowGoToTopButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 1000) {
        setShowGoToTopButton(true);
      } else {
        setShowGoToTopButton(false);
      }
    });
  }, []);

  // This function will scroll the window to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth" // use "smooth" for scrolling smoothly
    });
  };

  const appBody = (visibility: string) => {
    return (
      <Router basename="/">
        <ScrollToTop />
        <div className="App" style={{ display: visibility }}>
          <Header />
          <Switch>
            {/* Empty route for home */}
            <Route exact path="/">
              <Home />
            </Route>
            {/* Defines sections dynamically based on sections array */}
            {Constants.sections.map((section: any, key: any) => (
              < Route
                exact
                path={`/${section.path}`}
                key={key}
                component={componentRegistry[section.componentName]}
              ></Route>
            ))}
            {Constants.sections.map((section: any, key: any) => (
              section.children?.map((child: any, key: any) => (
                <Route
                  exact
                  path={`/${section.path}/${child.path}`}
                  key={key}
                  component={componentRegistry[child.componentName]}
                ></Route>
              ))
            ))}
          </Switch>
          <Footer />
          {showGoToTopButton && (
            <button onClick={scrollToTop} className="back-to-top">
              &#8963;
            </button>
          )}
        </div>
      </Router >
    );
  };

  return (
    // Set overflow: hidden to prevent horizontal movement if an element is overflowing to the right:
    <div style={{ overflow: "hidden" }}>
      {/* Classes to keep element centered: */}
      <div className="d-flex flex-row justify-content-center align-items-center">
        {/* <Spin
            className="spinnerContainer"
            style={{
              display: assetsFinishedLoading === true ? "none" : "block",
            }}
            size="large"
            indicator={spinnerImage}
          /> */}
      </div>
      {/* Return body created with dynamic sections, if there aren't any errors and it finished loading */}
      {/* Columns with dynamic width for large screen */}
      {/* <BlankSideColumns childComponent= */}
      {appBody("block")}
      {/* ></BlankSideColumns> */}
    </div>
  );
}

export default App;
