import React from "react";
import "antd/dist/antd.css";
import Row from 'react-bootstrap/Row';
import * as Constants from "../utils/constants";
import SloganRow from "./SloganRow";
import HeaderWithOptionalImage from "./HeaderWithOptionalImage";
import breakpointsHelper from "../utils/breakpointsHelper";
import { useWindowSize } from "@uidotdev/usehooks";

const EmpresaSingleRow = (props: {title: string, subtitle: string, bullets: string[], epigraph?: string, bgColor: string}) => {
  const size = useWindowSize();
  // @ts-ignore
  const horizontalPadding: string = breakpointsHelper(size.width, [
    "3rem",
    "4rem",
    "5rem",
    "6rem",
    "6rem",
  ])
  return (
    <Row style={{
      backgroundColor: props.bgColor,
      padding: `3rem ${horizontalPadding}`
      }}>
    <div style={{
        textAlign: "left",
        fontFamily: "utopiaSemiBold", 
        fontSize: "26px",
        color: "#323B98"
      }}>
    {props.title}
      </div>
      <div style={{
        textAlign: "left",
        fontFamily: "exo2SemiBold",
        fontSize: "18px",
        paddingTop: "1rem"
        }}
        >
      {props.subtitle}
      </div>
      <div style={{
        textAlign: "left",
        fontFamily: "exo2Regular",
        fontSize: "18px",
        paddingTop: "1rem"
        }}>
          {props.bullets.map((bullet, i)=>(
            <p style={{marginBottom: "0.5rem"}} key={i}>● {bullet}</p>
          ))}
      </div>
      {props.epigraph && <div style={{
        textAlign: "left",
        fontFamily: "exo2Regular",
        fontSize: "18px",
        paddingTop: "1rem"
        }}
        >
      {props.epigraph}
      </div>}
    </Row>
  )
}

const Empresas = () => {
  return (
    <>
      <HeaderWithOptionalImage title="Empresas" bgColor="#EDEDED" paragraph="Desarrollamos soluciones a medida, elaborando propuestas que agreguen valor a la Tesoreria de nuestros Clientes Corporativos, analizando cada situación puntual en el contexto de la conyuntura económica general." paragraphWidth="75%" bgImage={Constants.empresasBg}
        opacityLayerColor="transparent" />
        {Constants.empresaRowsData.map((empresaRowData, i)=>(
      <EmpresaSingleRow 
      key={i}
      title={empresaRowData.title}
      subtitle={empresaRowData.subtitle}
      bullets={empresaRowData.bullets}
      bgColor={empresaRowData.bgColor}
      epigraph={empresaRowData.epigraph}
    />
        ))}
      <SloganRow />
    </>
  );
}

export default Empresas;
