import React, { useState } from "react";
import { Redirect } from 'react-router';
import { Link } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { Button } from "react-bootstrap";
import BootstrapCarousel from 'react-bootstrap/Carousel';
import * as Constants from "../utils/constants";
import SloganRow from "./SloganRow";
import { useWindowSize } from "@uidotdev/usehooks";

const HomeRows = () => {
  const size = useWindowSize();
  const [redirect, setRedirect] = useState(false);
  const handleOnClick = () => {
    setRedirect(true);
  }
  if (redirect) {
    return <Redirect push to="/perfil_del_inversor" />;
  }

  const extendedBreakpointsHelper = (windowInnerWidth: number, sortedElements: any) => {
    if (windowInnerWidth < 600) {
      return sortedElements[0];
    } else if (windowInnerWidth < 768) {
      return sortedElements[1];
    } else if (windowInnerWidth < 992) {
      return sortedElements[2];
    } else if (windowInnerWidth < 1200) {
      return sortedElements[3];
    } else if (windowInnerWidth < 1500) {
      return sortedElements[4];
    } else {
      return sortedElements[5];
    }
  };

  const CarouselContent = (props: { requiredCols: any }) => {
    const cols: any = [];
    const size = useWindowSize();
// @ts-ignore
    const dynamicMinHeight = extendedBreakpointsHelper(size.width, [
      "340px",
      "300px",
      "380px",
      "550px",
      "435px",
      "360px"
    ])
    for (let i = 0; i < props.requiredCols.length; i++) {
      Constants.investmentProfiles[i] && cols.push(
        <Col key={i} xs={12} sm={12} md={6} lg={4} xl={4} style={{ padding: "1rem" }}>
          <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            boxShadow: "2px 3px 6px #0000006A",
            clipPath: "inset(0px -15px 0px 0px)"
          }}>
            <Image fluid src={Constants.investmentProfiles[props.requiredCols[i]].image} alt={Constants.investmentProfiles[props.requiredCols[i]].image} style={{
              flexShrink: 0,
              minWidth: "100%",
              minHeight: "100%"
            }} />
          </div>
          <div style={{ minHeight: dynamicMinHeight, textAlign: "left", padding: "1.5rem", lineHeight: "1rem", boxShadow: "2px 3px 6px #0000006A" }}>
            {Constants.investmentProfiles[props.requiredCols[i]].description.map((text, index: number) => (
              <div key={index} style={{ fontFamily: "Exo2Regular", marginBottom: "0.7rem",lineHeight: "1.5rem" }}>
                {text}
              </div>
            ))}
          </div>
        </Col>
      )
    }
    return (<Row>{cols}</Row>)
  }

  return (
    <>
      <Row>
        <Col xs={12} md={6} style={{ padding: 0, aspectRatio: "1/1" }}>
          <Image style={{ aspectRatio: "1/1" }} fluid src={Constants.losTiemposCambiaron} alt={Constants.losTiemposCambiaron} />
        </Col>
        <Col xs={12} md={6} style={{ padding: 0, aspectRatio: "1/1" }}>
          <Image style={{ aspectRatio: "1/1" }} fluid src={Constants.hombreNegociosTablet} alt={Constants.hombreNegociosTablet} />
        </Col>
      </Row>
      <Row className="custom-inverted-row">
        <Col xs={12} md={6} style={{ padding: 0, aspectRatio: "1/1" }}>
          <Image style={{ aspectRatio: "1/1" }} fluid src={Constants.mobileApp} alt={Constants.mobileApp} />
        </Col>
        <Col xs={12} md={6} style={{ padding: 0, aspectRatio: "1/1" }}>
          <Link
            className="nav-link"
            to="/contactanos"
            style={{
              color: "white",
              padding: 0,
              margin: 0,
              fontFamily: "Exo2Regular"
            }}
          >
            <Image style={{ aspectRatio: "1/1" }} fluid src={Constants.ahoraInvertirEsFacil} alt={Constants.ahoraInvertirEsFacil} />
          </Link>
        </Col>
      </Row>
      <Row style={{ padding: "4rem 6rem 1rem 6rem", color: Constants.primaryColor, fontFamily: "utopiaSemibold", fontSize: "33px", textAlign: "left" }}>
        Descubrí qué tipo de inversor sos
      </Row>
      <Row style={{ padding: "0 6rem 3rem 6rem", color: "rgba(50, 59, 152, 1)", fontFamily: "Exo2Regular", fontSize: "24px", textAlign: "left" }}>
        Un inversor define su perfil en función al capital disponible, su aversión al riesgo, objetivos de rendimiento esperado y plazo de inversión, entre otras características.
      </Row>
      <Row className="custom-investment-profiles-carousel custom-monthly-reports-carousel-container" style={{ padding: "0 6rem" }}>
        {/* @ts-ignore */}
        {size.width < 768 ?
          <BootstrapCarousel interval={4000}>
            <BootstrapCarousel.Item>
              <CarouselContent requiredCols={[0]} />
            </BootstrapCarousel.Item>
            <BootstrapCarousel.Item>
              <CarouselContent requiredCols={[1]} />
            </BootstrapCarousel.Item>
            <BootstrapCarousel.Item>
              <CarouselContent requiredCols={[2]} />
            </BootstrapCarousel.Item>
          </BootstrapCarousel>
          // @ts-ignore
          : size.width < 992 ?
            <BootstrapCarousel>
              <BootstrapCarousel.Item>
                <CarouselContent requiredCols={[0, 1]} />
              </BootstrapCarousel.Item>
              <BootstrapCarousel.Item>
                <CarouselContent requiredCols={[2]} />
              </BootstrapCarousel.Item>
            </BootstrapCarousel>
            :
            <BootstrapCarousel>
              <BootstrapCarousel.Item>
                <CarouselContent requiredCols={[0, 1, 2]} />
              </BootstrapCarousel.Item>
            </BootstrapCarousel>
        }
      </Row>
      <Row style={{ padding: "1rem" }}>
        <Col>
          <Button
            id="header-button-alt"
            style={{ fontFamily: "HelveticaLTStd-Roman", fontSize: "20px" }}
            onClick={handleOnClick}
          >
            Hacer el test de inversor
          </Button>
        </Col>
      </Row>
      <SloganRow />
    </>
  );
}

export default HomeRows;