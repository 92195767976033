import React from "react";
import * as Constants from "../utils/constants";
import RowWithBgImage from "./RowWithBgImage";
import HeaderWithOptionalImage from "./HeaderWithOptionalImage";
import SloganRow from "./SloganRow";

const Alliances = () => {
  return (
    <>
      <HeaderWithOptionalImage title="Alianzas" bgColor="#EDEDED" opacityLayerColor="transparent" paragraph="Desde Longo Elia Bursátil SA, a través de nuestras Alianzas establecidas con las más prestigiosas Administradoras de Fondos Locales e Internacionales, trabajamos para ofrecer a nuestros clientes las mejores alternativas de inversión.//Brindamos un servicio que garantiza de esta forma la independencia, ampliando la diversidad de opciones, adecuando cada estrategia de inversión al perfil de cada cliente." paragraphWidth="85%" />
      <RowWithBgImage
        title="Administradores locales"
        titleColor={Constants.primaryColor}
        bgImage={Constants.admLocales}
        smallImages={[
          [Constants.adm1, Constants.adm2],
          [Constants.adm3, Constants.adm4],
          [Constants.adm5, Constants.adm6],
          [Constants.adm7, Constants.adm8]
        ]}
        controls={true}
      />
      <RowWithBgImage
        title="Administradores globales"
        titleColor="#00DB94"
        bgImage={Constants.admGlobales}
        smallImages={[
          [Constants.admG1, Constants.admG2],
          [Constants.admG3, Constants.admG4],
          [Constants.admG5, Constants.admG6],
          [Constants.admG7, Constants.adm3],
          [Constants.admG9, Constants.admG10],
          [Constants.admG11, Constants.admG12]
          // ,
          // [Constants.admG8]
        ]}
        controls={true}
      />
      <SloganRow />
    </>
  )
}

export default Alliances;