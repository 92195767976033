import React from "react";
import DonutChart from './DonutChart';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

interface IChartData { title: string, reportLink: string, data: { title: string, value: number, color: string }[] }

const ChartsRow = (props: { title: string, chartsData: IChartData[], backgroundColor?: string }) => {
  return (
    <>
      <Row className="custom-charts-row-title" style={{ padding: "4rem 6rem 0 6rem", color: "#00DB94", backgroundColor: props.backgroundColor ?? "rgba(237, 237, 237, 0.74)", fontFamily: "utopiaSemibold", fontSize: "32px", textAlign: "left" }}>
        {props.title}
      </Row>
      <Row style={{ padding: "4rem 6rem 3rem 6rem", backgroundColor: props.backgroundColor ?? "rgba(237, 237, 237, 0.74)" }}>
        {props.chartsData.map((chartData: IChartData, i: number) => (
          <Col key={i}>
            <DonutChart
              title={chartData.title}
              reportLink={chartData.reportLink}
              radius={50} lengthAngle={-360} startAngle={270} lineWidth={20} animate animationDuration={1000}
              data={chartData.data}
            />
          </Col>
        ))}
      </Row>
    </>
  )
}

export default ChartsRow;