import React, { useEffect, useState } from "react";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import * as Constants from "../utils/constants";
import HeaderWithOptionalImage from "./HeaderWithOptionalImage";
import InvestmentTestTabs from "./InvestmentTestTabs";
import SloganRow from "./SloganRow";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const InvestorProfile = () => {
  const [testPrompts, setTestPrompts] = useState(null);
  const [testLevels, setTestLevels] = useState(null);
  useEffect(() => {
    axios.get(Constants.getAllPromptsURL).then((response) => {
      setTestPrompts(response.data.boxes);
    });
    axios.get(Constants.getInvestmentLevelsURL).then((response) => {
      setTestLevels(response.data);
    });
  }, []);
  return (
    <>
      <HeaderWithOptionalImage title="Perfil del inversor" paragraph="Descubrí qué tipo de inversor sos" bgColor="#EDEDED" bgImage={Constants.investorQuizBg} opacityLayerColor="transparent" />
      {testPrompts ? <InvestmentTestTabs investmentPrompts={testPrompts} investmentLevels={testLevels} /> :
        <Row style={{ padding: "3rem" }}>
          <Col>
            <Spinner animation="border" variant="primary" />
          </Col>
        </Row>
      }
      <SloganRow />
    </>
  )
}

export default InvestorProfile;