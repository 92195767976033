import React from "react";
import LinesEllipsis from "react-lines-ellipsis";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BootstrapCarousel from 'react-bootstrap/Carousel';
import breakpointsHelper from "../utils/breakpointsHelper";
import { useWindowSize } from "@uidotdev/usehooks";

const MonthlyCarousels = (props: { monthlyData: any, deviceType?: any }) => {
  const size = useWindowSize();

  // @ts-ignore
  const numCols: number = breakpointsHelper(size.width, [1, 1, 2, 3, 3]);

  const SetOfCarouselColumns = (props: { data: any, index: number, numCols: number }) => {
    const cols = [];
    for (let i = 0; i < props.numCols; i++) {
     
      props.data[i] && cols.push(
        <Col key={i} xs={12} sm={12} md={6} lg={4} xl={4}>
          <a style={{ textDecoration: "inherit" }}
           href={`http://winvest.ar/reports/${props.data[i].filename.split('/')[props.data[i].filename.split('/').length-1]}`} target="_blank" rel="noreferrer">
            <div
              style={{
                textAlign: "left", padding: "2rem", backgroundColor: "#F0EFF8", fontFamily: "Exo2Regular", fontSize: "16px", border: "none", height: "20rem"
              }}>
              <div style={{ fontFamily: "exo2Regular", fontSize: "16px", color: "#3B3B3A" }}>
                {props.data[i].date}
              </div>
              <div style={{ color: "#3B3B3A", fontFamily: "exo2Regular", fontSize: "21px" }}>
                {props.data[i].title}
              </div>
              <div style={{ fontFamily: "exo2Regular", fontSize: "16px", height: "9rem", color: "#707070", paddingTop: "2rem" }}>
                <LinesEllipsis
                  text={props.data[i].description}
                  maxLine='4'
                  ellipsis='...'
                  trimRight
                  basedOn='letters'
                />
              </div>
            </div>
          </a>
        </Col>
      )
    }
    return (<>{cols}</>)
  };
  return (
    <>
      {props.monthlyData.map((singleMonthData: any, index: number) => (
        <React.Fragment key={index}>
          <div className="custom-reports-carousel-container custom-monthly-reports-carousel-container" style={{ padding: "2rem 6rem", backgroundColor: "#FFFFFF" }}>
            <Row className="custom-row" style={{ color: "rgba(50, 59, 152, 1)", padding: "3rem 6rem" }}>
              <Col style={{ textAlign: "left", fontFamily: "Exo2SemiBold", fontSize: "32px" }}>
                {singleMonthData.month}
              </Col>
            </Row>
            <BootstrapCarousel interval={4000}>
              {singleMonthData.data.map((reportData: any, index: number) => (
                singleMonthData.data[index * numCols] &&
                <BootstrapCarousel.Item key={index} style={{ backgroundColor: "#FFFFFF", padding: "1rem" }}>
                  <Row>
                    <SetOfCarouselColumns
                      data={singleMonthData.data.slice(index * numCols, 2 * numCols + index)}
                      index={index}
                      numCols={numCols}
                    />
                  </Row>
                </BootstrapCarousel.Item>
              ))}
            </BootstrapCarousel>
          </div>
        </React.Fragment>
      ))}
    </>
  )
}

export default MonthlyCarousels;