import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from 'react-bootstrap/Image';
import Button from "react-bootstrap/Button";
import * as Constants from "../utils/constants";
import HeaderWithOptionalImage from "./HeaderWithOptionalImage";
import SloganRow from "./SloganRow";

const Accounts = () => {
  return (
    <>
      <HeaderWithOptionalImage title="Apertura de cuentas" bgColor="#EDEDED" />
      <Row className="justify-content-md-center custom-products-row" style={{ padding: "3rem 6rem 0 6rem", backgroundColor: "#EDEDED" }} xs={12} md={1} lg={3} xl={4}>
        {Constants.accountsTextParagraphs.map((textParagraph: any, i: number) => (
          <Col className="custom-products-col" key={i} xs={12} md={3} style={{ fontFamily: "exo2Regular", fontSize: "14px", color: "#3B3B3A", backgroundColor: "#FFF", boxShadow: "2px 3px 6px #00000029", margin: "1rem", padding: "0 2rem 2rem 2rem", border: "none" }}>
            <div style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
              // overflow: "hidden",
              minHeight: "8rem"
            }}>
              <Row style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden"
              }}>
                <Image fluid src={textParagraph.image} alt={textParagraph.image}
                  style={{
                    width: "60%",
                    aspectRatio: "1/1",
                    flexShrink: 0
                  }} />
              </Row>
            </div>
            <Row>
              <div style={{ fontFamily: "utopiaSemibold", fontSize: "18px", minHeight: "3rem", marginTop: "1rem", marginBottom: "1rem" }}>
                {textParagraph.title}
              </div>
            </Row>
            <Row>
              <div style={{ minHeight: "7rem", fontSize: "14px", lineHeight: "1rem" }}>
                {textParagraph.paragraph}
              </div>
            </Row>
            <Row>
              <div>
                <Button variant="primary" type="submit"
                  id="header-button-alt"
                  onClick={() => window.open(textParagraph.buttonLink, "_blank")}
                >
                  {textParagraph.buttonText}
                </Button>
              </div>
            </Row>
          </Col>
        ))}
      </Row>
      <SloganRow />
    </>
  )
}

export default Accounts;