import React from "react";
import HeaderWithOptionalImage from "./HeaderWithOptionalImage";
import SloganRow from "./SloganRow";
import Row from "react-bootstrap/Row";

const TerminosYCondiciones = () => {
  return (
    <>
      <HeaderWithOptionalImage title="Términos y Condiciones" bgColor="#EDEDED" />
      <Row className="container-vertically-centered" style={{ padding: "5rem 0", color: "#323B98", backgroundColor: "rgba(237, 237, 237, 1)" }}>
        <div className="custom-long-text-container" style={{ fontFamily: "Exo2Regular", fontSize: "16px", width: "60%", display: "block", textAlign: "left", margin: "auto" }}>

          <h5 style={{ color: "#323B98", fontFamily: "Exo2Semibold", fontSize: "16px" }}>
            Restricciones de Acceso
          </h5>
          <p>
            La información contenida en este Sitio Web (el “Sitio”) no debe ser usada en ningún país o región o por cualquier persona donde el uso constituiría una violación de la ley. Si esto se aplica a Ud., no está autorizado a usar cualquier tipo de información de este Sitio.
          </p>
          <br />
          <h5 style={{ color: "#323B98", fontFamily: "Exo2Semibold", fontSize: "16px" }}>
            Condiciones de Uso
          </h5>
          <p>
            Agradeceremos que lea estos términos y condiciones (las “Condiciones de Uso”) cuidadosamente ya que aplican a su acceso y/o uso de este Sitio. Accediendo a este Sitio y a cualquier página contenido en él, usted reconoce su acuerdo con la misma y su comprensión de estas Condiciones de Uso. Si usted no está de acuerdo con las Condiciones de Uso, por favor no ingrese o use este Sitio. Las Condiciones de Uso están sujetas a ser cambiadas en cualquier momento. Este Sitio es propiedad y operado por Longo Elia Bursatil (la “Compañía”), y es ella quien la administra.
          </p>
          <br />
          <h5 style={{ color: "#323B98", fontFamily: "Exo2Semibold", fontSize: "16px" }}>
            Condiciones Generales
          </h5>
          <p>
            El material y la información del Sitio, son proporcionados como “son” y sin ninguna garantía de ningún tipo, ya sea expresa o implícita. Rechazamos toda garantía que incluya garantías implícitas de comercialización y adecuación a un propósito específico. No ofrecemos ninguna garantía o representación en relación con el contenido, funcionalidad, enlaces o comunicaciones, lo que incluye ninguna representación o garantía en relación con defectos, errores o correcciones del mismo, interrupciones en la comunicación, interrupciones o pérdida de información o la ausencia de virus u otros componentes dañinos.
          </p>
          <p>
            En ningún caso la Compañía o alguna de sus subsidiarias o afiliadas será responsable ante parte alguna por costo alguno o daños que incluyan cualquier daño directo, indirecto, especial, accidental o como consecuencia de, que surja o este en conexión con el ingreso o uso, o la imposibilidad de ingresar o usar este Sitio o alguna de sus funciones que incluyan o estén relacionadas a la revisión o descarga de información, datos, texto, imágenes u otro material accesible a través de este Sitio o cualquier página web conectada o que este en conexión a este Sitio.
          </p>
          <p>
            Es la responsabilidad del usuario de este Sitio evaluar la precisión, totalidad, confiabilidad y utilidad de las opiniones, servicios u otra información que se suministre a través de ésta. Toda la información que se proporciona en este Sitio está bajo la condición y entendimiento de la misma y no debe ser interpretada o se debe contar con ella como una asesoría legal, contable, tributaria, financiera, de inversiones u otra asesoría profesional o como un consejo sobre asuntos u hechos específicos. Por lo tanto, no asumimos ninguna responsabilidad por el uso o mal uso de dicha información. Los usuarios de este Sitio deben consultar a sus asesores profesionales respecto a sus circunstancias personales.
          </p>
          <br />
          <h5 style={{ color: "#323B98", fontFamily: "Exo2Semibold", fontSize: "16px" }}>
            Ningún Ofrecimiento
          </h5>
          <p>
            Nada de este Sitio debe ser interpretado como una solicitud, oferta, asesoría, recomendación o algún otro servicio para adquirir o disponer de ninguna inversión o para comprometerse en alguna inversión o transacción. Para información respecto a algún producto específico y/o país, favor visitar la web respectiva.
          </p>
          <br />
          <h5 style={{ color: "#323B98", fontFamily: "Exo2Semibold", fontSize: "16px" }}>
            Propiedad Intelectual
          </h5>
          <p>
            Excepto si se indica lo contrario, toda información, texto, imágenes gráficas, características o funciones y diseño (incluyendo “lo que se siente y como se ve”) contenidas en este Sitio son de propiedad exclusiva de la Compañía y no deberían ser copiadas o distribuidas en su totalidad o en parte sin el consentimiento expreso por escrito de la Compañía.
          </p>
          <p>
            Sin embargo, usted puede bajar o imprimir algunas páginas de este Sitio para su uso personal, no comercial siempre y cuando no retire ninguno de los textos sobre derechos de autor.
          </p>
          <br />
          <h5 style={{ color: "#323B98", fontFamily: "Exo2Semibold", fontSize: "16px" }}>
            Enlaces
          </h5>
          <p>
            Los enlaces de este Sitio a otras páginas web se proporcionan solo para su información y conveniencia. La Compañía no asume ninguna responsabilidad o compromiso respecto a alguna página web enlazada desde este Sitio (o cualquier página web que se enlace a este Sitio), incluyendo su contenido y operación. La Compañía no revisa o monitorea dichos enlaces. Algunas páginas web pueden ser del extranjero por lo que están sujetas a diferentes regulaciones y normas.
          </p>
          <p>
            Un enlace desde este Sitio a otra página web (o un enlace desde otra página web a este Sitio) no constituye un respaldo, endoso, aprobación, publicidad, oferta o solicitud con respecto a dicha página web, su contenido o cualquier producto o servicio anunciado o distribuido a través de esa página web. La Compañía considera que los enlaces que proporciona a otras páginas web con acceso público, páginas web, grupos de noticias y otras fuentes es algo legalmente permisible y consistente con las expectativas comunes y acostumbradas de aquellos que hacen uso de Internet.
          </p>
          <p>
            Sin el consentimiento escrito de la Compañía, ninguna persona ni ninguna página web puede hacer un enlace a este Sitio, ya sea a este homepage o cualquier página interna.
          </p>
          <br />
          <h5 style={{ color: "#323B98", fontFamily: "Exo2Semibold", fontSize: "16px" }}>
            Monitoreo
          </h5>
          <p>
            La Compañía no está obligada a monitorear ninguna presentación de información o transmisión de esta al presente Sitio o a través de esta o a cualquier website a la cual este Sitio esta enlazada o que se enlace a este Sitio. Sin embargo, la Compañía se reserva el derecho de monitorear la misma, incluyendo cualquier chat room o web similar. Usted admite que a dicha información no se le adjuntara ninguna obligación de ningún tipo y que al presentar o transmitir dicha información, no se crea ninguna relación confidencial o contractual implícita u otra relación entre usted y la Compañía.
          </p>
          <br />
          <h5 style={{ color: "#323B98", fontFamily: "Exo2Semibold", fontSize: "16px" }}>
            Conducta del Usuario
          </h5>
          <p>
            Cuando usted ingrese o haga uso de cualquier parte de este Sitio, usted no debe:
          </p>
          <p>
            Enviar o transmitir ninguna información ilegal, amenazante, abusiva, difamatoria, obscena, pornográfica, profana u otra información objetable de cualquier tipo, que incluya sin limitaciones cualquier transmisión que constituya o aliente una conducta que podría constituirse en una ofensa delictiva, dar lugar a una responsabilidad civil o de otra manera violar alguna ley nacional o internacional;
          </p>
          <p>

            Enviar o transmitir alguna información o software que contenga un virus, gusano, caballo de Troya u otro componente dañino. Interferir en el funcionamiento de este Sitio o restringir o inhibir a algún usuario de este Sitio; .
          </p>
          <p>
            Enviar, publicar, transmitir, reproducir, distribuir o sacar provecho de alguna información, software u otro material obtenido a través de este Sitio con fines comerciales sin consentimiento escrito previo de la Compañía.
          </p>
          <p>
            Advertencia: Cualquier intento que haga alguna persona por dañar este Sitio o por minar su funcionamiento puede ser una violación de las leyes civiles o penales. La Compañía se reserva el derecho de indagar los daños que haya realizado dicha persona con todo el alcance de la ley.
          </p>
          <br />
          <h5 style={{ color: "#323B98", fontFamily: "Exo2Semibold", fontSize: "16px" }}>
            Leyes Aplicables y Jurisdicción.
          </h5>
          <p>
            Restricciones Legales Locales, Actualizaciones, Otros Este Sitio y las Condiciones de Uso que aquí se mencionan deberán ser interpretados, entrar en vigencia y usados de acuerdo a las leyes locales.
          </p>
          <br />
          <p>
            <b style={{ fontFamily: "Exo2Semibold" }}>La Compañía no afirma</b> que alguna información, materiales o funciones que se incluyen sean adecuadas para ser usadas en otra jurisdicción en donde (debido a la nacionalidad, residencia, ciudadanía u otro de la persona) la publicación o disponibilidad del Sitio y su contenido sea contradictorio a las leyes o regulaciones locales. Las personas respecto a las cuales dichas restricciones o prohibiciones aplican no deben usar o ingresar a este Sitio. Al ingresar o usar este Sitio, usted lo hace a su propia iniciativa y es el único responsable para cumplir con las leyes y regulaciones locales vigentes. Las actualizaciones y modificaciones a las Condiciones de Uso aquí descritos están sujetos a cambios en cualquier momento. Asegúrese de revisar estas Condiciones de Uso cada vez que usted ingrese y/o haga uso de este Sitio.
          </p>
        </div>
      </Row>
      <SloganRow />
    </>
  )
}

export default TerminosYCondiciones;